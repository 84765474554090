import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "browsers--devices"
    }}>{`Browsers & Devices`}</h1>
    <blockquote style={{
      borderLeft: '4px solid yellow'
    }}>
    Browser support is subject to change as customers continue to move off of older technologies.
    </blockquote>
    <h2 {...{
      "id": "supported-browsers"
    }}>{`Supported Browsers`}</h2>
    <p>{`Progress Manufacturing supports the `}<strong parentName="p">{`latest, stable release`}</strong>{` of all major browsers and platforms. On Windows, Progress supports `}<strong parentName="p">{`Internet Explorer 10-11 & EdgeHTML & Edge Chromium`}</strong>{`.`}</p>
    <p>{`Alternative browsers which use the latest version of WebKit, Blink, or Gecko, whether directly or via the platform’s web view API, are not explicitly supported. However, Progress-UI should (in most cases) display and function correctly in these browsers as well. More specific support information is provided below.`}</p>
    <p>{`You can find a list of our supported browsers in Material-UI's browser support list `}<a parentName="p" {...{
        "href": "https://github.com/mui-org/material-ui/blob/master/.browserslistrc"
      }}>{`.browserslistrc`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-/"
      }}>{`ie 11
edge >= 14
firefox >= 52
chrome >= 49
safari >= 10
node 8.0
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      